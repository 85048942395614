import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Toast, ToastContainer, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import './UserSignUp.css';
import img1 from '../images/Frame 1.png';

function SignupPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the request starts

    const user = {
      firstName,
      lastName,
      email,
      password,
      plan: "Not Subscribed"
    };

    try {
      const response = await fetch('https://sator-backend-4.onrender.com/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User created successfully:', data);
        setToastMessage("User Created");
        setShowToast(true);
        setTimeout(() => {
          navigate('/UserLogin');
        }, 2000); // Delay to show toast before navigating
      } else {
        setToastMessage("Failed to create user");
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage("Error!");
      setShowToast(true);
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false when the request is finished
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-content">
        <div className="signup-left">
          <div className="illustration">
            <img src={img1} alt="Illustration" />
          </div>
        </div>
        <div className="signup-right">
          <h2>Create a Free Account</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="6"
            />
            <button type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Sign Up"
              )}
            </button>
            <p>
              Already have an account? <Link to='/UserLogin'>Log in</Link>
            </p>
          </form>
        </div>
      </div>

      <ToastContainer
        position="top-end"  // Positioning the toast container at the top-right corner
        className="p-3"
        style={{ zIndex: 9999 }} // Ensure the toast is above other content
      >
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default SignupPage;
