import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavbarComponent from '../components/NavbarComponents';
import './details.css';
import { FaClock, FaUsers, FaRegCalendarAlt } from 'react-icons/fa';
import axios from 'axios';

function CourseDetail() {
  const { id } = useParams();
  const courseId = id; // No need to parseInt, it can be used as a string for the API call
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`https://sator-backend-4.onrender.com/courses/${courseId}`);
        setCourse(response.data);
      } catch (err) {
        setError('Course not found');
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [courseId]);

  const handleStartCourse = () => {
    if (course.modules && course.modules.length > 0) {
      // Redirect to the first module of the selected course
      navigate(`/course/${courseId}/module/${course.modules[0]._id}`);
    }
  };

  if (loading) {
    return (
      <div className="details-container">
        <NavbarComponent />
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="details-container">
        <NavbarComponent />
        <div className="not-found">{error}</div>
      </div>
    );
  }

  return (
    <div className="details-container">
      <NavbarComponent />
      <div className="course-detail">
        <div className="media-container">
          <img src={course.image} alt={course.title} className="course-image" />
        </div>
        <div className="course-content">
          <h1 className="course-title">{course.title}</h1>
          <p className="course-description">{course.description}</p>
          <div className="course-details">
            <div><FaClock className="icon" /> Duration: 10 hours</div>
            <div><FaUsers className="icon" /> Enrolled: 1200 students</div>
            <div><FaRegCalendarAlt className="icon" /> Last updated: July 2024</div>
          </div>
          <div className="course-actions">
            <button className="btn btn-primary" onClick={handleStartCourse}>Start Course</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetail;
