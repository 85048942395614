import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Toast, ToastContainer, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import './UserSignUp.css';
import img1 from '../images/Frame 1.png';

function SignupPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the request starts
  
    try {
      const response = await fetch('https://sator-backend-4.onrender.com/users');
      const users = await response.json();
  
      if (response.ok) {
        const user = users.find(user => user.email === email && user.password === password);
  
        if (user) {
          setToastMessage("Login Successful");
          setShowToast(true);
          // Save user data to session storage as "sator student"
          sessionStorage.setItem('sator student', JSON.stringify(user));
          setTimeout(() => {
            navigate('/UserDashboard');
          }, 2000); // Delay to show toast before navigating
        } else {
          setToastMessage("Error! Invalid email or password");
          setShowToast(true);
        }
      } else {
        setToastMessage("Error fetching users!");
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage("Error!");
      setShowToast(true);
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false once the request is done
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-content">
        <div className="signup-left">
          <div className="illustration">
            <img src={img1} alt="Illustration" />
          </div>
        </div>
        <div className="signup-right">
          <h2>Login into Your Account</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="6"
            />
            <button type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Login"
              )}
            </button>
            <p>
              Don't have an account? <Link to='/register'>Sign Up</Link>
            </p>
          </form>
        </div>
      </div>

      <ToastContainer
        position="top-end"  // Positioning the toast container
        className="p-3"
        style={{ zIndex: 9999 }} // Ensure the toast is above other content
      >
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default SignupPage;
