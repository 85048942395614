import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import NavbarComponent from '../components/NavbarComponents';
import './UserDashboard.css';

const truncateText = (text, maxLength = 100) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

function ColorSchemesExample() {
  const [displayedPopularCourses, setDisplayedPopularCourses] = useState([]);
  const [viewAllPopular, setViewAllPopular] = useState(false);

  const [displayedRecommendedCourses, setDisplayedRecommendedCourses] = useState([]);
  const [viewAllRecommended, setViewAllRecommended] = useState(false);

  const navigate = useNavigate();

  // Fetch data from API
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://sator-backend-4.onrender.com/courses');
        const data = await response.json();

        // Randomly shuffle the courses and pick 4
        const popularCourses = data.sort(() => 0.5 - Math.random()).slice(0, 4);
        const recommendedCourses = data.sort(() => 0.5 - Math.random()).slice(0, 4);

        setDisplayedPopularCourses(popularCourses);
        setDisplayedRecommendedCourses(recommendedCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const handleViewAllPopular = () => {
    setViewAllPopular(!viewAllPopular);
  };

  const handleViewAllRecommended = () => {
    setViewAllRecommended(!viewAllRecommended);
  };

  const handleGoToCourse = (id) => {
    navigate(`/course/${id}`);
  };

  return (
    <>
      <NavbarComponent />
      <div className="dashboard-container">
        <div className="dashboard-text">
          Investing in Knowledge and <br /><span className="future">Your Future</span>
        </div>
      </div>

      <Container>
        <div className="courses-section">
          <div className="courses-header m-3">
            <h2 className="section-heading">Popular Courses</h2>
            <Link onClick={handleViewAllPopular} className="view-all-link">
              {viewAllPopular ? 'View Less' : 'View All'}
            </Link>
          </div>

          <div className="row row-cols-1 row-cols-md-2 g-4">
            {displayedPopularCourses.map((course) => (
              <div className="col" key={course._id}>
                <div className="card border-0 shadow" data-aos="fade-down-right">
                  <img
                    src={course.image}
                    className="card-img-top"
                    alt={course.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{course.title}</h5>
                    <p className="card-text">{truncateText(course.description)}</p>
                    <button onClick={() => handleGoToCourse(course._id)} className="btn btn-primary">View Course</button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="courses-header m-3">
            <h2 className="section-heading">Recommended Courses</h2>
            <Link onClick={handleViewAllRecommended} className="view-all-link">
              {viewAllRecommended ? 'View Less' : 'View All'}
            </Link>
          </div>

          <div className="row row-cols-1 row-cols-md-2 g-4">
            {displayedRecommendedCourses.map((course) => (
              <div className="col" key={course._id}>
                <div className="card border-0 shadow" data-aos="fade-down-right">
                  <img
                    src={course.image}
                    className="card-img-top"
                    alt={course.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{course.title}</h5>
                    <p className="card-text">{truncateText(course.description)}</p>
                    <button onClick={() => handleGoToCourse(course._id)} className="btn btn-primary">View Course</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
}

export default ColorSchemesExample;
