// src/SignUpPage.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Welcome.css';
import img1 from '../images/logo.c30f671ff867a45eb01a.webp';
import Spinner from 'react-bootstrap/Spinner';

function SignUpPage() {
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate(); // React Router hook for navigation

  const handleButtonClick = (path) => {
    // Show spinner
    setShowSpinner(true);

    // Simulate delay and then navigate
    setTimeout(() => {
      // Hide spinner
      setShowSpinner(false);

      // Navigate to the desired page
      navigate(path); // Use React Router to navigate
    }, 3000); // 3000 milliseconds = 3 seconds
  };

  return (
    <div className="welcome-signup-container">
      {showSpinner && (
        <div className="spinner-overlay">
          <Spinner animation="grow" variant="success" />
        </div>
      )}
      <div className="logo">
        <img src={img1} alt="Logo" className="logo-image" />
      </div>
      <h1 className='text'>
        Welcome to <span className="highlight">Sartor</span> learning platform! <br />
        Get ready to embark on a thrilling learning experience
      </h1>
      <div className="btn">
      <button
        className="google-btn"
        onClick={() => handleButtonClick('/register')}
      >
        Sign Up
      </button>
      <Link to='/UserLogin' style={{ textDecoration: 'none' }}>
        <button className="email-btn">
          Sign In
        </button>
      </Link>
      </div>
    </div>
  );
}

export default SignUpPage;
