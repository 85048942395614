import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import img1 from '../images/Frame 1.png';

const TutorSignUp = () => {
  const navigate = useNavigate();

  // Separate useState hooks for each form field
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFullnameChange = (e) => setFullname(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!fullname || !email || !password) {
      setErrorMessage('Please fill out all fields.');
      return;
    }

    // Construct FormData
    const form = new FormData();
    form.append('fullname', fullname);
    form.append('email', email);
    form.append('password', password);

    // Log form data before sending
    console.log('Form data:', {
      fullname,
      email,
      password
    });

    try {
      const response = await axios.post('https://sartor-backend.onrender.com/API/v1/admin/SignUp', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
      });

      // Debug logs for the response
      console.log('Full response:', response);
      console.log('Admin created:', response.data);

      if (response.status === 200) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error creating admin:', error);

      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error response data:', error.response.data);
        setErrorMessage(error.response.data.message || 'Error creating account');
      } else {
        setErrorMessage('Error creating account');
      }
    }
  };

  return (
    <div className="container2">
      <div className='sign-up-image'>
        <img src={img1} alt="Sign Up" />
      </div>
      <div className="form-wrapper">
        <div className="image-section"></div>
        <div className="form-section">
          <h2 className="title">Create a Free Account as a Tutor</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="input"
              placeholder="Fullname"
              name="fullname"
              value={fullname}
              onChange={handleFullnameChange}
              required
            />
            <input
              type="email"
              className="input"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <input
              type="password"
              className="input"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button type="submit" className="sign-up-button">Sign Up</button>
          </form>
          <div className="link">
            <p>Already have an account? <Link to="/login" style={{ color: '#06aa5d', textDecoration: 'none' }}>Log in</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TutorSignUp;
