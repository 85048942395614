import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import img1 from '../images/Frame 1.png';

const TutorSignUp = () => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("Profile picture");

  const handleFileChange = (e) => {
    const name = e.target.files[0]?.name || "Profile picture";
    setFileName(name);
  };

  return (
    <div className="container2">
      <div className='sign-up-image'>
        <img src={img1} alt="Sign Up" />
      </div>
      <div className="form-wrapper">
        <div className="image-section"></div>
        <div className="form-section">
          <h2 className="title">Create a Free Account as an Admin</h2>
          <div className="input-icon-wrapper">
            <input
              type="file"
              id="file"
              className="file-input"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-input-label">
              {fileName}
            </label>
          </div>
          <input type="email" className="input" placeholder="Email" required />
          <div className="input-icon-wrapper">
            <input type="text" className="input-with-icon" placeholder="Username" required />
          </div>
          <input type="password" className="input" placeholder="Input password" required />
          <input type="password" className="input" placeholder="Confirm password" required />
          <button className="sign-up-button">Sign Up</button>
          <div className="link">
            <p>Already have an account? <Link to="/login" style={{color: '#06aa5d', textDecoration: 'none'}}>Log in</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TutorSignUp;
