import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Calendly } from 'react-calendly';
import { InlineWidget } from 'react-calendly';
import { useNavigate } from 'react-router-dom'; // for navigation
import NavbarComponent from './NavbarComponents';

function Resources() {
    const [isSubscribed, setIsSubscribed] = useState(true); // State to track subscription
    const [loading, setLoading] = useState(true); // Loading state while checking
    const navigate = useNavigate(); // Hook to navigate programmatically

    // Function to check the user's subscription plan from session storage
    const checkUserPlan = () => {
        const user = JSON.parse(sessionStorage.getItem('sator student')); // Get the user from session storage
        
        if (user && user.plan && user.plan === 'Not Subscribed') {
            setIsSubscribed(false); // User is not subscribed
        } else {
            setIsSubscribed(true); // User is subscribed
        }
        setLoading(false); // Done checking
    };

    useEffect(() => {
        checkUserPlan(); // Run the check when the component mounts
    }, []);

    const handleEventScheduled = (e) => {
        const meetingDetails = e.data.payload;
        console.log('Meeting Details:', meetingDetails);
        // Send meeting details to the backend
        fetch('http://sator-backend-4.onrender.com/api/meeting-details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventName: meetingDetails.event_name,
                startTime: meetingDetails.start_time,
                endTime: meetingDetails.end_time,
                invitee: {
                    name: meetingDetails.invitee_name,
                    email: meetingDetails.invitee_email,
                },
            }),
        })
            .then((response) => response.json())
            .then((data) => console.log('Success:', data))
            .catch((error) => console.error('Error:', error));
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading state while checking the subscription
    }

    if (!isSubscribed) {
        return (
            <Container className="mt-5">
                 <NavbarComponent />
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="shadow-sm mt-5">
                            <Card.Body>
                                <Card.Title>Access Denied</Card.Title>
                                <Card.Text>
                                    You are not subscribed to a plan. Please select a plan to gain access.
                                </Card.Text>
                                <button 
                                    className="btn btn-primary" 
                                    onClick={() => navigate('/plan')}>
                                    Choose a Plan
                                </button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            <NavbarComponent />
            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="shadow-sm mt-5">
                            <Card.Img
                                variant="top"
                                src="https://cdn.tree.ly/strapi/large_Book_a_Meeting_Shareimage_0a95b67311.jpg"
                                alt="Meeting"
                            />
                            <Card.Body>
                                <Card.Title>Book a Meeting</Card.Title>
                                <Card.Text>
                                    Schedule your meeting with us at a convenient time. Use the form below to book through Calendly.
                                </Card.Text>
                                <InlineWidget 
                                    url="https://calendly.com/tezzertech/30min" 
                                    onEventScheduled={handleEventScheduled}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Resources;
