import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import NavbarComponent from '../components/NavbarComponents'; // Adjust the path if necessary
import axios from 'axios';
import './UserDashboard.css';

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get('https://sator-backend-4.onrender.com/courses');
        console.log(response.data); // Debugging line to inspect API response
        setCourses(response.data); // Assuming the API returns an array of courses
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleGoToCourse = (courseId) => {
    const course = courses.find((course) => course._id === courseId);

    if (course && course.modules && course.modules.length > 0) {
      const firstModuleId = course.modules[0]._id; // Use `_id` instead of `id`
      navigate(`/course/${courseId}/module/${firstModuleId}`);
    } else {
      console.error('No modules found for this course.');
    }
  };

  const truncateText = (text, maxLength = 100) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <>
      <NavbarComponent />
      <div className="courses-page-container" style={{ paddingTop: '70px' }}> {/* Adjust padding-top to account for the fixed navbar */}
        <Container>
          <div className="courses-section">
            <div className="courses-header">
              <h2 className="section-heading">All Courses</h2>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {courses.map((course) => (
                <div className="col" key={course._id}>
                  <div className="card border-0 shadow" data-aos="fade-down-right">
                    <img
                      src={`${course.image}`}
                      className="card-img-top"
                      alt={course.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{course.title}</h5>
                      <p className="card-text">{truncateText(course.description)}</p>
                      <button onClick={() => handleGoToCourse(course._id)} className="btn btn-primary">
                        View Course
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CoursesPage;
