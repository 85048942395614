import React from 'react';
import './style.css';
import { FaUserAlt, FaBook, FaUserGraduate, FaBars, FaBell, FaHome, FaEnvelope, FaCog } from 'react-icons/fa';
import { RxExit } from "react-icons/rx";
import img1 from '../images/Frame 1.png';
import img2 from '../images/Sartor-logo.png'

const AdminDashboard = () => {
  return (
    <div className="dashboard-container">
      <aside className="admin-sidebar">
        <div className="admin-sidebar-header">
          <img src={img2} alt="" />
          <div className="user-info">
            <img src={img1} alt="" className="admin-profile-image"/>
            <div className='admin-info'>
              <p>Jonathan Richard</p>
            </div>
          </div>
        </div>
        <nav className="admin-sidebar-nav">
          <ul>
            <li className="active"><FaHome /> Dashboard</li>
            <li><FaBook /> Course Management</li>
            <li><FaUserAlt /> User Management</li>
            <li><FaEnvelope /> Messages</li>
            <li><FaCog /> Settings</li>
            <li><RxExit /> Logout</li>
          </ul>
        </nav>
       
      </aside>
      <main className="main-content">
        <header className="header">
          <div className="header-left">
            <FaBars />
            <input type="text" placeholder="input search text" />
          </div>
          <div className="header-right">
            <FaBell />
            <img src="admin-avatar-url" alt="Admin Avatar" />
          </div>
        </header>
        <div className="stats">
          <div className="stat-card">
            <h3>Registered Users</h3>
            <p>100</p>
          </div>
          <div className="stat-card">
            <h3>Number of Courses</h3>
            <p>20</p>
          </div>
          <div className="stat-card">
            <h3>Enrolled Students</h3>
            <p>80</p>
          </div>
        </div>
        <section className="course-overview">
          <header>
            <h2>Course Overview</h2>
            <a href="#!">View all</a>
          </header>
          <table>
            <thead>
              <tr>
                <th>Course Title</th>
                <th>Tutor</th>
                <th>Category</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {/* Replace with dynamic content */}
              <tr>
                <td>
                  <img src="course-img-url" alt="Course" />
                  Course Title Course Title
                </td>
                <td>John Adedayo</td>
                <td>Aspiring Entrepreneur</td>
                <td className="status pending">Pending</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </section>
        <section className="enrollment-info">
          <header>
            <h2>Enrolment Information</h2>
            <a href="#!">View all</a>
          </header>
          <table>
            <thead>
              <tr>
                <th>Course Title</th>
                <th>Tutor</th>
                <th>Number of Enrolled Students</th>
              </tr>
            </thead>
            <tbody>
              {/* Replace with dynamic content */}
              <tr>
                <td>Course Title Course Title</td>
                <td>Chioma Johnson</td>
                <td>10</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default AdminDashboard;
