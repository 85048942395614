import NavbarComponent from "./NavbarComponents";
import { MdOutlineBusiness } from 'react-icons/md';
import { TbBrandAmazon } from 'react-icons/tb';
import { FaChartLine } from 'react-icons/fa6';
import { MdLeaderboard } from 'react-icons/md';
import { SlTrophy } from 'react-icons/sl';
import { Container } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Plan() {
    return (
        <>
            <NavbarComponent />
            <div className="container mt-5">
                <div className="row justify-content-between text-center">
                    <div
                        className="col-md-5 p-5 bg-white shadow me-4 mb-sm-3 sartor-sm-mb"
                        data-aos="zoom-in"
                    >
                        <MdOutlineBusiness style={{ fontSize: "60px" }} />
                        <h2 data-aos="zoom-out">Start a Business</h2>
                        <p>Don’t know where or how to start?</p>
                        <p>Business plans</p>
                        <p>Business registration</p>
                        <p>Business launch</p>
                        <p>We’ve got you covered!</p>
                        <Link to="/start-business">
                            <Button style={{ backgroundColor: "#182659", padding: "15px 25px", borderRadius: "15px", border: "none" }}>
                                <span style={{ color: "white", fontSize: "18px", fontWeight: "bold" }}>Get Started</span>
                            </Button>
                        </Link>
                    </div>
                    <div
                        className="col-md-5 p-5 bg-white shadow mb-sm-3 sartor-sm-mb"
                        data-aos="zoom-in"
                    >
                        <TbBrandAmazon style={{ fontSize: "60px" }} />
                        <h2 data-aos="zoom-out">Build A Brand</h2>
                        <p>Build your identity to gain trust and loyal customers.</p>
                        <p>Brand Audit</p>
                        <p>Brand Strategy</p>
                        <p>We ensure your business shines by analyzing, refining, and optimizing every facet of your business for a lasting brand impact.</p>
                        <Link to="/brand-description">
                            <Button style={{ backgroundColor: "#182659", padding: "15px 25px", borderRadius: "15px", border: "none" }}>
                                <span style={{ color: "white", fontSize: "18px", fontWeight: "bold" }}>Get Started</span>
                            </Button>
                        </Link>
                    </div>
                    <div
                        className="col-md-5 p-5 bg-white shadow me-4 mb-sm-3 sartor-sm-mb"
                        data-aos="zoom-in"
                    >
                        <FaChartLine style={{ fontSize: "60px" }} />
                        <h2 data-aos="zoom-out">Grow Your Business</h2>
                        <p>Fuel your prosperity through tailored services such as:</p>
                        <p>Strategic Planning & Innovation</p>
                        <p>Market Research & Analysis</p>
                        <p>Sales & Marketing Strategy</p>
                        <p>Deals, Partnerships, and more</p>
                        <Link to="/grow-business-description">
                            <Button style={{ backgroundColor: "#182659", padding: "15px 25px", borderRadius: "15px", border: "none" }}>
                                <span style={{ color: "white", fontSize: "18px", fontWeight: "bold" }}>Get Started</span>
                            </Button>
                        </Link>
                    </div>
                    <div
                        className="col-md-5 p-5 bg-white shadow mb-sm-3 sartor-sm-mb"
                        data-aos="zoom-in"
                    >
                        <SlTrophy style={{ fontSize: "60px" }} />
                        <h2 data-aos="zoom-out">Become a Leader</h2>
                        <p>Lead and others will follow.</p>
                        <p>Leadership Development</p>
                        <p>Industry-Specific Focus</p>
                        <p>Personalized programs to enhance your leadership skills.</p>
                        <p>Strategic guidance to help you become an industry leader.</p>
                        <Link to="/leader-description">
                            <Button style={{ backgroundColor: "#182659", padding: "15px 25px", borderRadius: "15px", border: "none" }}>
                                <span style={{ color: "white", fontSize: "18px", fontWeight: "bold" }}>Get Started</span>
                            </Button>
                        </Link>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Plan;