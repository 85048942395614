import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import NavbarComponent from '../components/NavbarComponents';
import './details.css';
import { FaClock } from 'react-icons/fa';

function ModuleDetail() {
  const { id, moduleId } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`https://sator-backend-4.onrender.com/courses/${id}`);
        setCourse(response.data);
      } catch (error) {
        setError('Error fetching course data.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Find the module
  const module = course?.modules?.find(module => module._id === moduleId);

  // Check if the module is found
  if (!course) {
    return (
      <div className="details-container">
        <NavbarComponent />
        <div className="not-found">Course not found</div>
      </div>
    );
  }

  if (!module) {
    return (
      <div className="details-container">
        <NavbarComponent />
        <div className="not-found">Module not found</div>
      </div>
    );
  }

  // Find the next module
  const moduleIndex = course.modules.findIndex(mod => mod._id === moduleId);
  const nextModule = course.modules[moduleIndex + 1];

  // Convert YouTube URL to embed URL
  const youtubeEmbedUrl = module.video && module.video.includes('youtube.com')
    ? module.video.replace('watch?v=', 'embed/')
    : module.video;

  return (
    <div className="details-container">
      <NavbarComponent />
      <div className="module-detail">
        <div className="media-container">
          {module.video ? (
            youtubeEmbedUrl ? (
              <iframe
                width="560"
                height="315"
                src={youtubeEmbedUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            ) : (
              // Display video source if not a YouTube URL
              <video width="560" height="315" controls>
                <source src={module.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          ) : (
            <div>No video available</div>
          )}
        </div>
        <div className="module-content">
          <h1 className="module-title">{module.title}</h1>
          <p className="module-description">{module.description}</p>
          <div className="module-details">
            <div><FaClock className="icon" /> Duration: {module.duration || 'N/A'}</div>
          </div>
          <div className="module-navigation">
            {nextModule ? (
              <Link to={`/course/${id}/module/${nextModule._id}`} className="btn btn-primary">Next Module</Link>
            ) : (
              <p>No more modules.</p>
            )}
            <Link to={`/course/${id}`} className="btn btn-secondary">Back to Course</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleDetail;
