import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import img1 from '../images/logo.c30f671ff867a45eb01a.webp';
import './UserDashboard.css';

const NavbarComponent = () => {
  const user = JSON.parse(sessionStorage.getItem('sator student'));
  const userName = user ? user.firstName : "Guest";
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    navigate('/');
  };

  return (
    <>
      <Navbar className="custom-navbar" data-bs-theme="dark" fixed="top" expand="md">
        <Container>
          <Navbar.Brand className="logo">
            <img src={img1} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link style={{ color: 'white' }} onClick={() => navigate('/UserDashboard')}>Dashboard</Nav.Link>
              <Nav.Link onClick={() => navigate('/courses')} style={{ color: 'white' }}>Courses</Nav.Link>
              <Nav.Link onClick={() => navigate('/Resources')} style={{ color: 'white' }}>Resources</Nav.Link>
              <Nav.Link onClick={() => navigate('/plan')} style={{ color: 'white' }}>Plan</Nav.Link>
            </Nav>
            <div className="ms-auto user-section">
              <FaUser style={{ color: 'white' }} />
              <span className="user-name" style={{ marginRight: '15px' }}>Welcome {userName}</span>
              <FaSignOutAlt style={{ color: 'white', marginLeft: '10px' }} />
              <Button  style={{ color: 'white', marginLeft: '10px', textDecoration: 'none' }} variant="link" className="logout-button" onClick={() => setShowModal(true)}>Logout</Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavbarComponent;
