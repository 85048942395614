import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Welcome from './components/Welcome';
import SignUp from './components/SignUp';
import TutorSignUp from './components/TutorSignUp';
import AdminSignUp from './components/AdminSignUp';
import UserLogin from './components/UserLogin';
import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';
import CourseDetail from './components/CourseDetail';
import NavbarComponent from './components/NavbarComponents';
import ModuleDetail from './components/ModulePage';
import AllCourses from './components/AllCourses';
import Resources from './components/Resources';
import Plan from './components/Plan';
import StartBusiness from './components/StartBusiness';
import BrandDescriptionPage from './components/BrandDescriptionPage';
import GrowBusiness from './components/GrowBusiness';
import LeaderDescription from './components/LeaderDescription';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Welcome />} exact />
        <Route path='/register' element={<SignUp />} exact />
        <Route path="/TutorSignUp" element={<TutorSignUp />} />
        <Route path="/AdminSignUp" element={<AdminSignUp />} />
        <Route path="/UserLogin" element={<UserLogin />} />
        <Route path="/Resources" element={<Resources />} />
        <Route path="/UserDashboard" element={<UserDashboard />} />
        <Route path="/AdminDashboard" element={<AdminDashboard />} />
        <Route path="/NavbarComponent" element={<NavbarComponent />} />
        <Route path="/courses" element={<AllCourses />} />
        <Route path="/course/:id" element={<CourseDetail />} />
        <Route path="/course/:id/module/:moduleId" element={<ModuleDetail />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/start-business" element={<StartBusiness />} />
        <Route path="/brand-description" element={<BrandDescriptionPage />} />
        <Route path="/grow-business-description" element={<GrowBusiness />} />
        <Route path="/leader-description" element={<LeaderDescription />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
